import Bugsnag from '@bugsnag/js'

window.Bugsnag = Bugsnag

if window.BUGSNAG_JS_API_KEY
  Bugsnag.start
    apiKey: window.BUGSNAG_JS_API_KEY
    appVersion: window.APP_VERSION
    releaseStage: window.RELEASE_STAGE
    user: window.CURRENT_USER
else
  console.warn 'BUGSNAG_JS_API_KEY not set, not initializing bugsnag'
